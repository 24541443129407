.navContainer {
    background-color: var(--grey);
    text-align: center;
    color: var(--off-white);
    top: 0px;
    width: 100%;
    position: sticky;
}

.navName {
    color: var(--off-white);
    text-decoration: none;
    float: right;
    font-weight: bold;
    margin: 20px;
}

.navBar {
    margin-left: 20px;
    float: left;
}

@media only screen and (max-device-width: 480px) {
    .navBar {
        margin-left: 0px;
    }
}

.navName:hover {
    color: var(--off-white);
    text-decoration: none;
    animation-name: flicker-1;
    animation-duration: .2s;
    animation-timing-function: linear;
	animation-fill-mode: forwards;
}

.navLink {
    margin-top: 20px;
    color: var(--off-white);
    text-decoration: none;
    padding: 10px;
    border-radius: 2px;
    display: inline-block;
    font-weight: bold;
    font-size: 20px;
}

.navLink:hover {
    background-color: var(--off-white);
    color: var(--grey);
    animation-name: rise;
    animation-duration: .2s;
    animation-fill-mode: forwards;
    font-weight: bold;
    display: inline-block;
}

.navLink:active {
    animation-name: fall;
    animation-duration: .2s;
    animation-fill-mode: forwards;
}

@keyframes rise {
    from {
        // box-shadow: 0px 0px #494947;
        transform: translate(0px, 0px);
    }
    to {
        // box-shadow: 0px 5px #494947;
        transform: translate(0px, -5px);
    }
}

@keyframes fall {
    from {
        // box-shadow:0px 5px #D3D3D3;
        transform: translate(0px, -5px);
    }
    to {
        // box-shadow: 0px 0px #D3D3D3;
        transform: translate(0px, 0px);
    }
}