.projectsContainer {
    width: var(--list-width);
    justify-content: center;
}

@media screen and (max-width: 480px) {
    .projectsContainer {
        margin-left: 0%;
        width: var(--list-width-mobile);
    }
}