.App {
  animation-duration: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: var(--off-white);
  margin-bottom: 8%;
}

.markdown {
  width: 75%;
  text-align: left;
  font-size: 20px;
  color: var(--off-white);
  margin-top: 20px;
  background-color: var(--grey);
  padding: 20px;
}

@media only screen and (max-device-width: 480px)
{
  .markdown {
      width: 80%;
      margin-bottom: 10%;
  }
}

.markdown h1, h2{
  text-align: center;
}

.markdown a {
  padding: 0px;
  margin: 0px;
  color: var(--off-white);
  text-decoration: none;
  font-weight: bold;
}

.markdown a:hover {
  color: var(--grey);
  background-color: var(--off-white);
  padding: 2px;
  margin: -2px;
  border-radius: 4px;
}

.markdown hr {
  width: 50%;
}
