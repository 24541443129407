:root {
  --grey: #494947;
  --off-white: #fce3cb;
  --orange: #ee6c4d;
  --cyan: #49beaa;
  --sky-blue: #7494ea;

  --list-width: 80%;
  --list-width-mobile: 100%
}

body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-image: url('/public/assets/images/hex.png');
  background-color: var(--off-white);
  background-size: 25%;
  background-repeat: repeat;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: f;
  font-size: x-small;
  src: url('/public/assets/fonts/DePixelBreit.otf');
  font-weight: lighter;
}

h1 {
  font-family: f;
}