.opening {
    background-image: url("code_example.gif");
    background-size: cover;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    background-color: var(--grey);
    text-decoration: none;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    z-index: 10;
}

.opening h1 {
    color: var(--orange);
	text-decoration: none;
	transform: translate(0%, 0%);
    animation-duration: 1s;
    background-color: var(--grey);
    height: 10%;
    padding: 10px;
    border-radius: 5px;
    font-family: f;
}

.opening h1:hover {
    color: var(--off-white);
}

@media only screen and (max-device-width: 480px) {
    .opening {
        background-image: none;
    }
}