.blogCardContainer {
    width: var(--list-width);
    display: table;
    margin-bottom: 10%;
}

.blogCardContainer a {
    text-decoration: none;
}

@media screen and (max-width: 1000px) {
    .blogCardContainer {
        width: var(--list-width-mobile);
    }
}