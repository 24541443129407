.cardContainer {
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: grid;
    justify-content: center;
    font-size: 25px;
    width: 100%;
}

.cardContainer:hover {
    color: var(--grey);
}

.languages {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.languages * {
    padding: 5px;
    margin-left: 5px;
    background-color: var(--off-white);
    color: var(--grey);
    border-radius: 3px;
}

@media screen and (max-width: 480px) {
    .cardContainer {
        width: 100%;
        border-radius: 0px;
    }
}

