.blockCardList {
    font-size: 20px;
    color: var(--off-white);
    padding: 10px;
    border-radius: 3px;
    text-align: left;
    font-weight: bold;
}

.blockCardList:hover {
    background-color: var(--orange);
    color: var(--grey);
}

.blockCardList li {
    display: inline-block;
    width: 33%;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .blockCardList {
        border-radius: 0;
    }

    .blockCardList li {
        display: grid;
        padding-top: 5px;
        text-align: left;
        width: 100%;
    }
}